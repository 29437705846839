@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

.command {
  white-space: break-spaces;
  //word-wrap: break-word;
  font-family: var(--bs-font-monospace) !important;
  background-color: #fff3cd;
  cursor: pointer;
  border: 1px solid #c9bda0;
  border-radius: 4px;
  padding: 1px 2px;
  font-size: 0.9em;
  font-weight: bold;

  &:hover {
    background-color: #ffe79b;
  }
}
